import * as Sentry from '@sentry/nextjs';
import Contentful from 'contentful';
import { GetServerSideProps } from 'next';

import { getCopiesSet } from '../components/pages/home/utils/getCopiesSet';
import { usePageVistTracker } from '../hooks/usePageVistTracker';
import { getContentfulClient } from '../utils/getContentfulClient';
import { DeviceTypes } from '../utils/getDeviceType';
import { getSsrABTest } from '../utils/getSsrABTest';
import { HERO_COPIES_SSR_AB } from '../utils/SsrABTests';
import HomePage from './home';

declare global {
  const AppleID: any;
  const FB: any;
  const google: typeof import('google-one-tap');
}

export type CopiesSetData = {
  key: string;
  heroTitle: string;
  heroDesktopImage: Contentful.Asset;
  heroDesktopImageAlt: string;
  heroMobileImage: Contentful.Asset;
  heroMobileImageAlt: string;
  heroMobileAuraImage: Contentful.Asset;
  heroMobileAuraImageAlt: string;
  heroDescription: string;
  heroBottomTitle: string;
  heroBottomImage: Contentful.Asset;
  heroBottomImageAlt: string;
  secondScreenTitle: string;
  secondScreenDescription: string;
  secondScreenImage: Contentful.Asset;
  secondScreenImageAlt: string;
  secondScreenLinkText: string;
  thirdScreenTitle: string;
  thirdScreenDescription: string;
  thirdScreenLinkText: string;
  thirdScreenImage: Contentful.Asset;
  thirdScreenImageAlt: string;
  thirdScreenBadgeImage: Contentful.Asset;
  thirdScreenBadgeImageAlt: string;
  thirdScreenBadgeDescription: string;
  heroMobileImageHeight: number;
  heroMobileImageWidth: number;
  heroDesktopImageHeight: number;
  heroDesktopImageWidth: number;
};

export type CopiesSets = Contentful.EntryCollection<CopiesSetData>;

type Props = {
  deviceType?: DeviceTypes;
  copiesSet: CopiesSetData;
};

export const getServerSideProps: GetServerSideProps = async ({
  req,
  res,
}): Promise<{ props: Props }> => {
  try {
    const copiesSetKey = getSsrABTest(HERO_COPIES_SSR_AB, req, res);
    const client = getContentfulClient();
    const copiesSets = await client.getEntries<CopiesSetData>({
      content_type: 'homepageData',
    });
    const copiesSet = getCopiesSet(copiesSets, copiesSetKey);
    return {
      props: {
        copiesSet,
      },
    };
  } catch (e) {
    Sentry.captureException('Error occurred whilst fetching Home Page copies', {
      extra: { reason: e },
    });
  }
};

const Home = (props: Props): JSX.Element => {
  usePageVistTracker();

  return <HomePage copiesSet={props.copiesSet} deviceType={props.deviceType} />;
};

export default Home;
