import Head from 'next/head';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';

import { BROWSER_ENVIRONMENT, FACEBOOK_APP_ID, SELF_URL } from '../../globals';
import { Assets } from '../../utils/assets';
import FullPage from './FullPage';

interface LayoutProps {
  pageTitle?: string;
  description?: string;
  children?: ReactNode;
  shouldIndex?: boolean;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  pageTitle = `Brain.fm: Music to improve focus, meditation & sleep.`,
  description = `Brain.fm - Music designed for the brain to enhance focus, relaxation, meditation, naps and sleep within 10 - 15 minutes of use.`,
  shouldIndex = true,
}) => {
  const router = useRouter();

  return (
    <FullPage>
      <Head>
        {BROWSER_ENVIRONMENT !== 'production' || !shouldIndex ? (
          <meta content="noindex, nofollow" name="robots" />
        ) : null}
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <meta charSet="utf-8" />

        <meta content={description} name="description" />

        {/* Twitter */}
        <meta key="twcard" content="summary_large_image" name="twitter:card" />
        <meta key="twsite" content="@BrainfmApp" name="twitter:site" />
        <meta key="twhandle" content="@BrainfmApp" name="twitter:creator" />
        <meta key="twtitle" content={pageTitle} name="twitter:title" />
        <meta key="twdesc" content={description} name="twitter:description" />
        <meta key="twimage" content={Assets.images.socialLogoNew.url} name="twitter:image" />

        {/* Open Graph */}
        <meta key="ogurl" content={`${SELF_URL}${router.route}`} property="og:url" />
        <meta key="ogsitename" content="BrainFM" property="og:site_name" />
        <meta key="ogtitle" content={pageTitle} property="og:title" />
        <meta key="ogdesc" content={description} property="og:description" />
        <meta key="ogimage" content={Assets.images.socialLogoNew.url} property="og:image" />
        <meta key="ogimagealt" content={Assets.images.socialLogoNew.alt} property="og:image:alt" />
        <meta key="ogtype" content="website" property="og:type" />

        <meta key="fbid" content={FACEBOOK_APP_ID} property="fb:app_id" />

        {/* Search Rich Results */}
        <script
          dangerouslySetInnerHTML={{
            __html: `{
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Brain.fm | Music to Focus Better",
              "description": "Use Brain.fm's functional music to help you focus relax and sleep better, using cutting-edge neuroscience to get results.",
              "url": "${SELF_URL}",
              "logo": "${Assets.images.logoPurple.url}",
              "sameAs": [
                "https://www.facebook.com/brainfm",
                "https://twitter.com/BrainfmApp",
                "https://www.instagram.com/brainfmapp/",
                "https://www.youtube.com/@brainfmapp",
                "https://www.linkedin.com/company/brain.fm/",
                "https://www.brain.fm/",
                "https://open.spotify.com/artist/6LsRviViXR7UshzkUtfRDu"
              ]
            }`,
          }}
          type="application/ld+json"
        />

        <title>{pageTitle}</title>
      </Head>
      {children}
    </FullPage>
  );
};

export default Layout;
