import { HomePage } from '../../components/pages/home';
import Layout from '../../components/shared/Layout';
import { usePageVistTracker } from '../../hooks/usePageVistTracker';
import { DeviceTypes } from '../../utils/getDeviceType';
import { CopiesSetData } from '../index';

const HomePageWrapper = (props: {
  deviceType?: DeviceTypes;
  copiesSet: CopiesSetData;
}): JSX.Element => {
  usePageVistTracker();

  return (
    <Layout
      description="Use Brain.fm's functional music to help you focus relax and sleep better, using cutting edge neuroscience to get results."
      pageTitle="Music to Focus Better - Brain.fm"
    >
      <HomePage copiesSet={props.copiesSet} deviceType={props.deviceType} />
    </Layout>
  );
};

export default HomePageWrapper;
